.topNav {
	position: fixed;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	top: 0;
	width: 100%;
	height: 3.5rem;
	z-index: 1000;
	background-color: var(--clr2);
	transition: background-color 0.3s ease;
}

.transparent {
	background-color: transparent;
	backdrop-filter: blur(6px);
	background-color: rgba(0, 0, 0, 0.4);

	@media (min-width: 768px) {
		.navItem span {
			--text-blur-color: rgba(33, 33, 33, 0.5);
			filter: drop-shadow(-1px -1px 1px var(--text-blur-color))
				drop-shadow(1px -1px 1px var(--text-blur-color))
				drop-shadow(-1px 1px 1px var(--text-blur-color))
				drop-shadow(1px 1px 1px var(--text-blur-color));
		}
	}
}

.navMain {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding: 0 0.5rem;
	max-width: 1200px;
	position: relative;
}

.navLogo {
	width: 3rem;
	height: inherit;
	transition: transform 0.2s ease;
	margin-right: 1rem;
}

.navLogo:hover {
	transform: scale(1.2);
}

.currentPage {
	display: none;
	color: white;
	font-size: 1.3rem;
}

.menuButton {
	display: none;
	background: none;
	border: none;
	color: white;
	cursor: pointer;
	padding: 0.5rem;
}

.navMenuList {
	display: flex;
	gap: 0.5rem;
	align-items: center;
}

.navItem {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	color: white;
	font-size: 1.2rem;
	background: none;
	border: none;
	cursor: pointer;
	transition: background-color 0.2s ease;
}

.navItem:hover,
.navItem.active {
	background-color: var(--clr3);
}

.navItem.active .dropdownIcon {
	transform: rotate(90deg);
}

.dropdownIcon {
	transition: transform 0.2s ease;
}

.submenuWrapper {
	position: relative;
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
}

.submenuContainer {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 1px;
	top: 100%;
	left: 0;
	min-width: 200px;
	background-color: var(--clr1);
	overflow: hidden;
	animation: slideDown 0.2s ease;
}

.submenuItem {
	display: block;
	padding: 0.75rem 1rem;
	color: white;
	background-color: var(--clr2);
	text-decoration: none;
	transition: all 0.2s ease;
}

.submenuLink {
	text-decoration: none;
}

.submenuItem:hover,
.submenuItem.active {
	background-color: var(--clr3);
}

@keyframes slideDown {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@media (max-width: 768px) {
	.topNav {
		display: block;
	}

	.navMain {
		justify-content: space-between;
		margin: 0 0.5rem;
	}

	.currentPage {
		display: block;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
		pointer-events: none;
	}

	.navLogo {
		margin-right: 0;
	}

	.menuButton {
		display: block;
		position: relative;
		box-shadow: none;
		min-width: 40px;
	}

	.navMenuList {
		position: absolute;
		top: 3.5rem;
		right: 0;
		width: auto;
		min-width: 200px;
		flex-direction: column;
		align-items: stretch;
		padding: 0.5rem;
		display: none;
		gap: 0.5rem;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	}

	.navMenuList.menuOpen {
		display: flex;
		background-color: var(--clr2);
	}

	.submenuContainer {
		position: static;
		gap: 1px;
		min-width: unset;
		margin: 0;
		background-color: var(--clr1);
	}

	.navItem,
	.submenuItem {
		width: 100%;
		text-align: left;
		padding: 0.5rem 1rem;
		background-color: var(--clr2);
	}

	.submenuItem {
		padding-left: 2rem;
	}

	.submenuItem:hover {
		background-color: var(--clr3);
	}

	.submenuWrapper {
		width: 100%;
		flex-direction: column;
		align-items: stretch;
	}
}
