@font-face {
	font-family: "Font Awesome 6 Brands";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/webfonts/fa-brands-400.woff2)
		format("woff2"),
		url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/webfonts/fa-brands-400.ttf)
		format("truetype");
}

@font-face {
	font-family: "Font Awesome 6 Free";
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/webfonts/fa-regular-400.woff2)
		format("woff2"),
		url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/webfonts/fa-regular-400.ttf)
		format("truetype");
}

@font-face {
	font-family: "Font Awesome 6 Free";
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/webfonts/fa-solid-900.woff2)
		format("woff2"),
		url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/webfonts/fa-solid-900.ttf)
		format("truetype");
}
