/* Post preview dvs varje länk */

.post-preview {
	height: 100%;
}
.post-preview h2 {
	font-size: 1.5rem;
	color: #1d1d1f;
	margin-bottom: 2px;
}

.post-preview h3 {
	font-size: 1.2rem;
	font-weight: 500;
	color: #333;
	margin: 6px 0px;
}

.post-preview p.meta {
	font-size: 1.1rem;
	padding: 0;
	margin-bottom: 0.5rem;
	color: #1d1d1f;
}

.post-preview p.subtitle {
	font-size: 1.2rem;
	color: var(--clr5);
	padding: 0;
	margin-bottom: 0rem;
}

.post-preview div.body p {
	padding: 0;
	color: #333;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.post-preview a {
	text-decoration: none;
	height: 100px;
}

.post-preview hr {
	margin-top: 0px;
}

@media screen and (max-width: 550px) {
	.feed-preview .post-preview {
		width: calc(100% - 3rem);
		grid-template-areas:
			"image meta"
			"image content";
	}
}
